// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

//追加

import 'bootstrap'
import '../stylesheets/application.scss'
import $ from 'jquery';
import jquery from 'jquery';
import '@fortawesome/fontawesome-free/js/all'

window.$ = window.jquery = jquery;
import select2 from 'select2';
import 'select2/dist/css/select2.css';

$(document).ready(function () {
    $('.mySelect2').select2();

    $('#new_list_purchase [name="list_purchase[list_purchase_pref_ids][]"]').on(
        'change',
        function () {
            console.log("change");
            $.ajax({
                url: "/company_data/" + $(this).val() + "/get_cities.js",
                type: "get"
            });
        }
    );

    const new_query = document.getElementById('new_list_purchase');

    const displaySpinner = (e) => {
        console.log("displaySpinner");
        $("#overlay").fadeIn(300);
    }

    if(new_query) {
        new_query.addEventListener('submit', displaySpinner);
    }
});